import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import extraReducer from './slices/extra';

// slices
import userReducer from './slices/user';

// api
import { authApi } from './api/authApi';
import { copyGroupApi } from './api/copyGroupApi';
import { groupApi } from './api/groupApi';
import { logApi } from './api/logApi';
import { orderApi } from './api/orderApi';
import { userApi } from './api/userApi';
import { walletAnalyzeApi } from './api/walletAnalyzeApi';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['extra',],
};

const rootReducer = combineReducers({
  extra: extraReducer,
  user: userReducer,

  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [walletAnalyzeApi.reducerPath]: walletAnalyzeApi.reducer,
  [groupApi.reducerPath]: groupApi.reducer,
  [copyGroupApi.reducerPath]: copyGroupApi.reducer,
  [logApi.reducerPath]: logApi.reducer,
});

export { rootPersistConfig, rootReducer };
