import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';

// api
import { authApi } from './api/authApi';
import { copyGroupApi } from './api/copyGroupApi';
import { groupApi } from './api/groupApi';
import { logApi } from './api/logApi';
import { orderApi } from './api/orderApi';
import { userApi } from './api/userApi';
import { walletAnalyzeApi } from './api/walletAnalyzeApi';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(userApi.middleware)
      .concat(authApi.middleware)
      .concat(orderApi.middleware)
      .concat(groupApi.middleware)
      .concat(copyGroupApi.middleware)
      .concat(walletAnalyzeApi.middleware)
      .concat(logApi.middleware)
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

setupListeners(store.dispatch);

export { dispatch, persistor, store, useDispatch, useSelector };
