import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: null,
  isOpenModal: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET
    getSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // CREATE
    createSuccess(state, action) {
      const newUser = action.payload;
      state.isLoading = false;
      state.users = [...state.users, newUser];
    },

    // UPDATE
    updateSuccess(state, action) {
      const user = action.payload;
      const updateUser = state.users.map((_user) => {
        if (_user.id === user.id) {
          return user;
        }
        return _user;
      });
      state.isLoading = false;
      state.users = updateUser;
    },

    // UPDATE
    updateStatusSuccess(state, action) {
      const user = action.payload;
      const updateUser = state.users.map((_user) => {
        if (_user.id === user.id) {
          return { ..._user, status: user.status };
        }
        return _user;
      });

      state.isLoading = false;
      state.users = updateUser;
    },

    // DELETE
    deleteSuccess(state, action) {
      const { id } = action.payload;
      const deleteUser = state.users.filter((user) => user.id !== id);
      state.users = deleteUser;
    },

    // SELECT
    selectUser(state, action) {
      const user = action.payload;
      state.user = user;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openViewModal, openModal, closeViewModal, closeModal, selectUser } = slice.actions;
