import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const groupApi = createApi({
  reducerPath: 'groupApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['GroupTag'],
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => `group`,
      providesTags: ['GroupTag'],
    }),

    addGroup: builder.mutation({
      query: (body) => ({
        url: `group`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GroupTag'],
    }),

    updateGroup: builder.mutation({
      query: ({ id, body }) => ({
        url: `group/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['GroupTag'],
    }),

    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `group/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GroupTag'],
    }),
  }),
});

export const { useAddGroupMutation, useDeleteGroupMutation, useUpdateGroupMutation, useGetGroupsQuery } = groupApi;
