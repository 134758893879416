import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const copyGroupApi = createApi({
  reducerPath: 'copyGroupApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['CopyGroupTag'],
  endpoints: (builder) => ({
    getCopyGroups: builder.query({
      query: () => `copy-group`,
      providesTags: ['CopyGroupTag'],
    }),

    addCopyGroup: builder.mutation({
      query: (body) => ({
        url: `copy-group`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CopyGroupTag'],
    }),

    updateCopyGroup: builder.mutation({
      query: ({ id, body }) => ({
        url: `copy-group/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CopyGroupTag'],
    }),

    deleteCopyGroup: builder.mutation({
      query: (id) => ({
        url: `copy-group/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CopyGroupTag'],
    }),
  }),
});

export const { useAddCopyGroupMutation, useDeleteCopyGroupMutation, useUpdateCopyGroupMutation, useGetCopyGroupsQuery } = copyGroupApi;
