import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
//
// ----------------------------------------------------------------------

const docTypes = [
  {
    id: 'Nuevo Fijo discontinuo APP',
    name: 'Nuevo Fijo discontinuo APP',
  },
  {
    id: 'Llamamiento APP',
    name: 'Llamamiento APP',
  },
  {
    id: 'Obra y Servicio APP',
    name: 'Obra y Servicio APP',
  },
  {
    id: 'Modificacio horaria APP',
    name: 'Modificacio horaria APP',
  },
];

const initialState = {
  isLoading: false,
  error: null,
  parents: [],
  years:[],
  currentYear:dayjs().format("YYYY"),
  docTypes: docTypes || [],
  activity: null,
  types: [],
  isOpenModal: false,
};

const slice = createSlice({
  name: 'extra',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET
    getParentSuccess(state, action) {
      state.isLoading = false;
      state.parents = action.payload;
    },

    // GET
    getYearSuccess(state, action) {
      state.isLoading = false;
      state.years = action.payload;
    },


    // GET
    setCurrentYear(state, action) {
      state.currentYear = action.payload;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, setCurrentYear } = slice.actions;
