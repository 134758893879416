// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, 'dashboard'),
    groups: path(ROOTS_DASHBOARD, 'groups'),
    copyGroups: path(ROOTS_DASHBOARD, 'copy-groups'),
    orders: path(ROOTS_DASHBOARD, 'orders'),
    users: path(ROOTS_DASHBOARD, 'users'),
    logs: path(ROOTS_DASHBOARD, 'logs'),
    setting: path(ROOTS_DASHBOARD, 'setting'),
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
